import React from "react";
import { useState , useEffect} from "react";
import "../../component/popup.css";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
function Popup(props) {
  const [amount , setAmount]= useState('0')
 
  const setsubmit=async()=>{
   if(amount>props.unsettleamount){
    toast.error('amount is more than unsettle amount')

    setTimeout(() => window.location.reload(), 2000);
   }else{
    try {
      
      const data = JSON.stringify({ amount: amount });
      const response = await axios.post(
        "https://api.sharkpe.in/merchant/Settelment",
         data,
      
        {
          headers: {
            'authorization': localStorage.getItem("token"),
            'Content-Type': 'application/json'
          },
        }
      );
      if (response) {
      console.log(response)
       toast.success('success');
       setTimeout(() => window.location.reload(), 2000);
      }else if(!response.success){
        toast.error(response.message)

        setTimeout(() => window.location.reload(), 2000);

      }
    } catch (error) {
      setTimeout(() => window.location.reload(), 2000);
      
      toast.error('There was some issue');
    }}
  }
  
  return (
    <div className="modalBackground">
      <ToastContainer/>
      <div className="card col-xl-4" style={{ height: "350px" }}>
        <div className="card-body">
          <div className="row">
          <div className='col-12 p-3'>
            <button style={{border:'none', float:'right'}}
             onClick={()=>{props.setPopup(false)}}><i class="bi bi-x-square"></i></button>
                </div>
            <div className="col-12">
            
              <div className="row">
                <div className="col-6 text-muted" style={{fontSize:'12px'}}> Settled Amount</div>
                <div className="col-6">{props.settleamount}</div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6 text-muted"  style={{fontSize:'12px'}}> UnSettled Amount</div>
                <div className="col-6">{props.unsettleamount}</div>
              </div>
            </div>
            <div className="col-10 mt-2">
              <div className="row">
                <div className="form-label mr-2">Amount</div>
                <input className="form-control p-2 mr-2" value={amount}
                 onChange={(e) => setAmount(e.target.value)} />
                {amount>props.unsettleamount?<small className="text-danger pt-1">*amount should be less than unsettled amount amount</small>:<></>}
                <button className="btn btn-primary mt-2 col-7 col-md-5"

                 onClick={setsubmit}>Submit</button>
                
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
