import React from 'react'
import { useState } from 'react';
import Header from '../component/header';
import HeaderMobile from '../component/headerMobile';
import SetUpiContent from '../content/setupiContent/setupi';

function SetUpi() {
    const[toggle ,setToggle] = useState(true);
    const toggler=()=>{
        setToggle(!toggle);
    }
    return ( 
    <div className='userTable' style={{minHeight:'100vh'}}>
        <div className='header-desktop'> <Header toggle={toggle} toggler={toggler} title={'Upi Payment'}/> </div>
        <div className='header-mobile'><HeaderMobile toggler={toggler} toggle={toggle}/></div>
     <div className={toggle?'body margin-active':'body margin-non-active '}>
        
            <div className='row pt-3 m-1'  style={{minHeight:'100vh'}}>
                <div className='col-12'>
                   <SetUpiContent/>
                </div>
            </div>
    
    
     </div>
    </div> );
}

export default SetUpi;