import React from 'react';
import './pages.css';
import { Link } from 'react-router-dom';
function Error() {
    return ( 
    <div className='authincation h-100'>
        <div className='container h-100'style={{alignSelf:'center'}}>
            <div className='row justify-content-center h-100 align-item-center'>
                <div className='col-md-7'>
                    <div className='form-input-content text-center error-page'>
                        <h1 className='error-text fw-bold'>
                           404
                        </h1>
                        <h3>
                        <i class="bi bi-exclamation-triangle-fill text-danger"></i>
                            The page you were looking for is not found!
                        </h3>
                        <p className='text-muted'>
                            You may have mistyped the address or the page may have moved.
                        </p>
                        <div>
                            <Link className='btn btn-primary' to='/'>
                                Back to Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> );
}

export default Error;