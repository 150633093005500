import React from 'react'
import { useState } from 'react';
import googlepay from '../../icons/googlepay.svg';
import paytm from'../../icons/paytm.svg';
import phonepe from '../../icons/phonepe.svg';

function SetUpiContent() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
     <div className='card'>
        <div className='card-header'>
            <h4 className='card-title' style={{color:'#9568FF'}}>
            Set Upi
            </h4>
        </div>
    </div> 
    <div className='card'>
        <div className='card-header'>
            <h4> Detail</h4>
            
            
            </div>
            <div className='card-body' >
                <button className='btn btn-primary col-12' onClick={()=>{setIsOpen(!isOpen)}} >
                    <div className='d-flex justify-content-between'>
                        <div>UPI PAYMENT</div>
                        {isOpen?<i class="bi bi-dash-lg" ></i>:<i class="bi bi-plus-lg"></i>}
                    </div>
                </button>
                <div className={isOpen?'inview col-12':'notinview'}>
                <div className='row'>
                <div className='col-12 d-flex justify-content-between mt-5'>
                        <img src={googlepay} alt='phone pe'></img>
                        <input placeholder='google pay id' style={{borderRadius:'10px' ,border:'2px solid #7135ff'}} className='col-7 me-4'></input>
                        <div class="form-check p-2 col-2">
                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label class="form-check-label" for="flexCheckDefault">
                         Active
                        </label> 
                        </div>
                    </div>

                    <div className='col-12 d-flex justify-content-between mt-5'>
                        <img src={paytm} alt='phone pe' ></img>

                      
                        <input placeholder='paytm id' style={{borderRadius:'10px' ,border:'2px solid #7135ff'}} className='col-7  me-4'></input>
                        <div class="form-check p-2 col-2">
                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label class="form-check-label" for="flexCheckDefault">
                         Active
                        </label>
                        
                        </div>
                    </div>
                   
                    <div className='col-12 d-flex justify-content-between mt-5'>
                        <img src={phonepe} alt='phone pe'></img>
                      
                        <input placeholder='phonepe id' style={{borderRadius:'10px' ,border:'2px solid #7135ff'}} className='col-7 me-4'></input>
                        <div class="form-check p-2 col-2">
                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label class="form-check-label" for="flexCheckDefault">
                         Active
                        </label>
                      
                        </div>
                    </div>
                 </div>
                 <button className='btn btn-success col-12 mt-5' > update</button>
                </div>
           
        </div>
    </div>
    </div>);
}

export default SetUpiContent;