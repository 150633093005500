import React from 'react';
import './userForm.css'
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';


const MyTextInput = ({ label, ...props }) => {
 
  const [field, meta] = useField(props);
  return (
    <div className='m-2'>
      <label class="form-label text-muted" style={{fontSize:'12px'}}htmlFor={props.id || props.name}>{label}</label>
      <input className="form-control p-2" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

const MyCheckbox = ({ children, ...props }) => {
 
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <div class="mb-3 ">
      <label className="checkbox-input form-label">
        <input className='chechbox'  type="checkbox" {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="error text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};


// And now we can use these
const UseForm = () => {
  return (
    <div>
     
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          mobilenumber:'',
          referral:'',
          address:'',

          acceptedTerms: false, // added for our checkbox
         
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('*Required'),
          lastName: Yup.string()
            .max(20, 'Must be 20 characters or less')
            .required('*Required'),
          email: Yup.string()
            .email('*Invalid email address')
            .required('*Required'),
          acceptedTerms: Yup.boolean()
            .required('*Required')
            .oneOf([true], '*You must accept the terms and conditions.'),
          mobilenumber: Yup.number()
  .typeError("That doesn't look like a phone number")
  .positive("A phone number can't start with a minus")
  .integer("A phone number can't include a decimal point")
  .min(10)
  .required('A phone number is required'),
            refferral: Yup.string().required('*Required'),
              Address:Yup.string(),

        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        <Form className='card p-2'>
           <h4 className= 'title'>Details</h4> 
         <div className='flex-card justify-content-between'> 
         <MyTextInput
            label="First Name"
            name="firstName"
            type="text"
            placeholder="Jane"
          />

          <MyTextInput
            label="Last Name"
            name="lastName"
            type="text"
            placeholder="Doe"
          />
       </div>
       <div className='flex-card  justify-content-between'> 
          <MyTextInput
            label="Email Address"
            name="email"
            type="email"
            placeholder="jane@formik.com"
          />

        <MyTextInput
            label="Mobile Number"
            name="mobilenumber"
            type="string"
            placeholder=""
          />
</div>
         <MyTextInput
            label="Refferal"
            name="refferal"
            type="referral"
            placeholder="url"
          />
          <MyTextInput
            label="Address"
            name="Address"
            type="Address"
            placeholder="Address"
          />
<div className='flex-card'>
<MyTextInput
            label="City"
            name="Address"
            type="Address"
            placeholder=""
          />
            <MyTextInput
            label="State"
            name="Address"
            type="Address"
            placeholder=""
          />
            <MyTextInput
            label="Zip Postal"
            name="Address"
            type="Address"
            placeholder=""
          />
           <MyTextInput
            label="country"
            name="Address"
            type="Address"
            placeholder=""
          />
</div>
         

           <button className='btn btn-primary col-sm-12 m-2' type="submit">submit changes</button>
           <div className='flex-card justify-content-between gap-1'>
            <button className=' btn btn-success col-4'>Verificartion</button>
            <button className=' btn btn-secondary col-4'>Verificartion</button>
            <button className=' btn btn-danger col-4'>Verificartion</button>



           </div>
        </Form>
      </Formik>
    </div>
  );
};
export default UseForm;