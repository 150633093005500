import axios from "axios";

import React, { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";
import { DateRangePicker } from "rsuite";
import moment from "moment";

function SalesAnalytics({wallet}) {
  const [Analysis, setAnalysis] = useState([]);
  const [date, setDate] = useState([]);
  const Analytics = async (st,ed) => {
    try {
      const headers = { Authorization: localStorage.getItem("token") };
      const req = await axios.post(
        "https://api.sharkpe.in/merchant/dashboard_analysis",
        {
          start_date: st,
          end_date: ed,
        },
        {
          headers: headers,
        }
      );
      const response = await req.data;
      setAnalysis(response);
      console.log(response);
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };

  
  useEffect(() => {
    Analytics(moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"));
  }, []);

  const handleAnalysis = (e) => {
    setAnalysis([])
    Analytics(moment(e[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss"),moment(e[0]).endOf("day").format("YYYY-MM-DD HH:mm:ss"));

  }

  return (
    <>
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <div className="row">
            <h4 className="text-primary col-6">Sales Analytics</h4>
            <div class="dropdown col-6 text-right">
              <DateRangePicker
                className="form-control"
                format="yyyy-MM-dd"
                showOneCalendar={true}
                defaultValue={[new Date(), new Date()]}
                onSelect={handleAnalysis}
                onChange={handleAnalysis}
              />
            </div>
          </div>
          {Analysis ? <div className="mt-2 col-12 full-border ">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="row px-2 mt-2">
                  <div className="text-dark">UnSetteled Amount</div>
                  <h5 className="text-dark">
                    INR
                    <span className="text-muted px-2">
                      {wallet?.hold_amount?.toFixed(2)}
                    </span>
                  </h5>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="row px-2 mt-2">
                  <div className="text-dark">Current Amount</div>
                  <h5 className="text-dark">
                    INR
                    <span className="text-muted px-2">{wallet.amount?.toFixed(2)}</span>
                  </h5>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12">
                <div className="row px-2 mt-2">
                  <div className="text-dark text-16-px">Total Payin</div>
                  <h5 className="text-dark">
                    INR{" "}
                    <span className="text-muted px-1">
                      {Analysis?.payin?.toFixed(2)}
                    </span>
                  </h5>
                </div>
                <div className="row px-2 mt-2">
                  <div className="text-dark text-16-px">
                    {" "}
                    Total Pending Settelment
                  </div>
                  <h5 className="text-dark">
                    INR{" "}
                    <span className="text-muted px-1">
                      {Analysis?.settelment?.pending?.toFixed(2)}
                    </span>
                  </h5>
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="row px-2 mt-2">
                  <div className="text-dark text-16-px">Total PayOut</div>
                  <h5 className="text-dark">
                    INR
                    <span className="text-muted px-1">
                      {Analysis?.payout?.toFixed(2)}
                    </span>
                  </h5>
                </div>
                <div className="row px-2 mt-2">
                  <div className="text-dark text-16-px"> Total Settelment</div>
                  <h5 className="text-dark">
                    INR
                    <span className="text-muted px-1">
                      {Analysis?.settelment?.success?.toFixed(2)}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>: <h3>Fetching Data ...</h3>}
        </div>
      </div>
    </>
  );
}

export default SalesAnalytics;
