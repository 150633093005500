import React from 'react'
function Cards() {
    return ( 
    <>
     <form className='card'>
      <div class="form-group card-body">
      <div for="exampleFormControlFile1" className='title'>Upload Image</div>
      <input type="file" class="form-control-file text-muted pb-2" id="exampleFormControlFile1" style={{fontSize:'10px'}}/>
      <h4 className='pt-2'>Name</h4>
      <h6 className='pt-1'>DOB</h6>
     </div>
     </form>

     <div className='card'>
      <div class="card-body">
        <div className='title'>User Info</div>
        <div className='info d-flex justify-content-between pb-4'>
            <div className='text-muted'>Name</div>
            <div>millie</div>
        </div>
        <div className='info d-flex justify-content-between pb-4'>
        <div className='text-muted'>status</div>
            <div style={{color:'green'}}>Active</div>
        </div>
        <div className='info d-flex justify-content-between pb-4'>
        <div className='text-muted'>Balance</div>
            <div>$1892.890</div>
        </div>
        <div className='info d-flex justify-content-between pb-4'>
        <div className='text-muted'>upline</div>
            <div>N/A</div>
        </div>
        <div className='info d-flex justify-content-between pb-2'>
        <div className='text-muted'>Downline</div>
            <div>0</div>
        </div>
     </div>
     </div>

     <div className='card'>
        <div className='card-body'>
            <div className='title'>User Action</div>
            <button className='btn btn-success col-12 p-2 mb-3'>Add Balance</button>
            <button className='btn btn-primary col-12 p-2 mb-3'>Add Balance</button>
            <button className='btn btn-danger col-12 p-2 mb-3'>Add Balance</button>

        </div>
     </div>
    </> );
}

export default Cards;