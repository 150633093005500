import React from "react";
import { useState } from "react";
import Header from "../component/header";
import HeaderMobile from "../component/headerMobile";
import PaymentApiContent from "../content/paymentApi-Content/paymentApiContent";
import PaymentApiForm from "../content/paymentApi-Content/paymentApiform";

function PaymentApi() {
  const [toggle, setToggle] = useState(true);
  const [reload , setReload]=useState(false);
  const toggler = () => {
    setToggle(!toggle);
  };
  return (
    <div className="userTable">
      <div className="header-desktop">
        {" "}
        <Header toggle={toggle} toggler={toggler} title={"Payment Api"} />{" "}
      </div>
      <div className="header-mobile">
        <HeaderMobile toggler={toggler} toggle={toggle} />
      </div>
      <div
        className={toggle ? "body margin-active" : "body margin-non-active "}
      >
        <div className="row pt-3 m-1"  style={{minHeight:'100vh'}}>
          {/* <div className="col-12"> */}
            {" "}
            {/* <PaymentApiForm  setReload={setReload} reload={reload}/> */}
          {/* </div> */}
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <PaymentApiContent setReload={setReload} reload={reload}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentApi;