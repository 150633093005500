import React from 'react'
import { useState } from 'react';
import Header from '../component/header';
import HeaderMobile from '../component/headerMobile';
import AddBankContent from '../content/addBank/addBankContenr';

function AddBank() {
    const[toggle ,setToggle] = useState(true);
    const toggler=()=>{
        setToggle(!toggle);
    }
    return ( 
    <div className='addBank' style={{backgroundColor:' #f0ecfe'}}>
        <div className='header-desktop'> <Header toggle={toggle} toggler={toggler} title={'Add Bank'}/> </div>
        <div className='header-mobile'><HeaderMobile toggler={toggler} toggle={toggle}/></div>
     <div className={toggle?'body margin-active':'body margin-non-active '} style={{minHeight:'100vh'}}>
        
            <div className='container-fluid'  >
                
               <AddBankContent/>
            </div>
    
    
     </div>
    </div> );
}

export default AddBank;