import axios from "axios";
import React from "react";
import DataTable from "react-data-table-component";
import Popup from "./popup";
import { useEffect, useState } from "react";

import { CSVLink } from "react-csv";
import PaymentApiForm from "./paymentApiform";
import ToggleButton from "react-toggle-button";
import { ToastContainer, toast } from "react-toastify";

function PaymentApiContent(props) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterdata, setFilterdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [updateModal, setupdateModal] = useState(false);
  // const [updateModalClose, setupdateModal] = useState(false);
  const [updateModalData, setupdateModalData] = useState([]);
  const title = (
    <div>
      {" "}
      <h3>Payment Api</h3> <br /> <h6>Click on UUID to generate API Key</h6>
    </div>
  );
  const getData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: token };
      const response = await axios.get("https://api.sharkpe.in/merchant/api/", {
        headers: headers,
      });
      console.log(response.data.data.rows);

      setFilterdata(response.data.data.rows);
      setData(response.data.data.rows);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, [props.reload]);

  useEffect(() => {
    const result = data.filter((info) => {
      return (
        info.label.toLowerCase().match(search.toLowerCase()) ||
        info.webhook_url.toLowerCase().match(search.toLowerCase()) ||
        info.createdAt.toLowerCase().match(search.toLowerCase()) ||
        info.callback_url.toLowerCase().match(search.toLowerCase()) ||
        info["updatedAt"].toLowerCase().match(search.toLowerCase())
      );
    });
    setFilterdata(result);
  }, [search, data]);

  const columns = [
    {
      name: "uuid",
      selector: (row) => <>{row.uuid} Api key</>,
      sortable: true,
      wrap: true,
    },

    { name: "label", selector: (row) => row.label, sortable: true, wrap: true },

    {
      name: "ip address",
      selector: (row) => row.ip_address,
      sortable: true,
      wrap: true,
    },
    {
      name: "secret_key",
      selector: (row) => row.secret_key,
      sortable: true,
      wrap: true,
    },

    {
      name: "callback url",
      selector: (row) => row.callback_url,
      sortable: true,
      wrap: true,
    },

    {
      name: "webhook url",
      selector: (row) => row.webhook_url,
      sortable: true,
      wrap: true,
    },

    {
      name: "status",
      selector: (row) => (
        <>
          {row.status ? (
            <div className="text-success">Success</div>
          ) : (
            <div className="text-error">Failed</div>
          )}
        </>
      ),
      sortable: true,
    },

    {
      name: "Created At",
      selector: (row) => row.createdAt,
      sortable: true,
      wrap: true,
    },

    {
      name: "Updated At",
      selector: (row) => row.updatedAt,
      sortable: true,
      wrap: true,
    },
  ];

  columns.push({
    name: "Disable",
    cell: (row) => (
      <>
        <ToggleButton
          value={row.status}
          onToggle={async (value) => {
            await update(row);
          }}
        />
      </>
    ),
  });

  const update = async (row) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: token };
      const response = await axios.post(
        "https://api.sharkpe.in/merchant/api/update",
        {
          id: row?.id,
          status: 0,
        },
        {
          headers: headers,
        }
      );

      toast.success("your key has been disabled ...");
      window.location.href = "";
    } catch (error) {
      toast.error("your key is not disabled ...");
      console.log(error);
    }
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "65px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "16px",
        color: "#22173f",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "#6c757d",
      },
    },
  };
  const handleChange = ({ selectedRows }) => {
    console.log("Selected Rows: ", selectedRows);
  };
  const whenclick = (row, event) => {
    console.log("clicked row:", row);
    setId(row.id);
    console.log(id);
    setOpen(true);
    console.log(open);
  };
  const rowperpage = (currentRowsPerPage, currentPage) => {
    console.log("currentpage", currentPage);
    console.log("currentrow", currentRowsPerPage);
  };

  return (
    <>
      <ToastContainer />
      {filterdata.length === 0 && <PaymentApiForm />}

      <DataTable
        title={title}
        columns={columns}
        data={filterdata}
        pagination
        paginationRowsPerPageOptions={[
          10, 20, 30, 50, 100, 200, 500, 1000, 1500,
        ]}
        fixedHeader
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        actions={
          <CSVLink
            data={data}
            onClick={() => {
              console.log("You click the link"); // 👍🏻 Your click handling logic
            }}
            className="btn btn-info"
          >
            export
          </CSVLink>
        }
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="search"
            className="form-control w-25"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        }
        customStyles={customStyles}
        onRowClicked={whenclick}
        onSelectedRowsChange={handleChange}
        onChangeRowsPerPage={rowperpage}
      />
      {open && <Popup setClose={setOpen} id={id} />}
      {/* {updateModal && <PopupApi setClose={setupdateModal} id={updateModalData}/>} */}
    </>
  );
}

export default PaymentApiContent;
