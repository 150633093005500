import React from 'react'
import NavLinks from '../content/navlinks';
import login from '../icons/login.png';
import Sharkpe from '../icons/Sharkpe.svg'
import './header.css';
function HeaderMobile(props) {
    return (
        <div>
         
          <NavLinks toggle={props.toggle}/>
       <div className='d-flex justify-content-between blur-bg'>
        <div className='d-flex'>
        <img src={Sharkpe} alt='' style={{height:'4rem'}}/>
        <button onClick={props.toggler} className='navbtn'><i class="bi bi-list"></i> </button>
        </div>
        
        
        <div className='sidebar-btn-mobile d-flex'>
          <div className='d-flex'>
             <button className='icon-btn'><i class="bi bi-gear-fill"></i></button>
            <button className='icon-btn'><i class="bi bi-chat-left-fill"></i></button>
            <button className='icon-btn'><i class="bi bi-bell-fill"></i></button>
            </div>
           <div><img src={login} alt='' className='login-img'/></div>
         </div>

         </div>
      
              
       </div>
     );
}

export default HeaderMobile;