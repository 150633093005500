import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import CommanTable from "../../pages/commanTable";
import ResPopup from "../../pages/ResPopup";
import { toast, ToastContainer } from "react-toastify";
import { HOST } from "../../constant";
import Popup from "reactjs-popup";
// import "./model.css";
import "reactjs-popup/dist/index.css";
import BankDeatil from "./bankDetail";

function PayoutDataTable(props) {
  const [data, setData] = useState([]);
  //const [search, setSearch] = useState("");
  // const [filterdata, setFilterdata] = useState([]);
  //const [open, setOpen] = useState(false);
  //const [id, setId] = useState();
  const [page, setPage] = useState("1");
  const [row, setRow] = useState("10");
  const [total, setTotal] = useState(0);
  const [BankDeatilPopup, setBankDeatilPopup] = useState(false);
  const [bankData, setbankData] = useState([]);
  const getData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: token };
      const response = await axios.get(
        `https://api.sharkpe.in/merchant/transaction/${page}/${row}/payout`,
        { headers: headers }
      );
      console.log(response.data);
      setTotal(response?.data.rows);
      //  setFilterdata(response.data.data);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, [props.reload, page, row]);
  const [popup, setPopup] = useState("");
  const [open, setOpen] = useState(false);
  const [dataPopup, setdataPopup] = useState("");
  const getResponse = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: token };
      const response = await axios.get(
        `https://api.sharkpe.in/merchant/transaction_status/${popup}/payout`,
        { headers: headers }
      );
      setdataPopup(response.data.data.response);
    } catch (error) {
      console.log(error);
    }
  };
  const transferDetails = async (row) => {
    try {
      setBankDeatilPopup(true);
      // console.log(HOST)
      const token = localStorage.getItem("token");
      const headers = { Authorization: token };
      const response = await axios.get(HOST + "bankdeatils/"+row.id, {
        headers: headers,
      });
      console.log({response})
      setbankData(response.data.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setdataPopup("");
    getResponse();
  }, [popup]);
  return (
    <>
      <ToastContainer />
      <CommanTable
        data={data}
        title="Payout"
        pageId="payout"
        setPage={(val) => setPage(val)}
        setPopup={(row) => setPopup(row.transaction_id)}
        row={row}
        setTotal={setTotal}
        page={page}
        total={total}
        setRow={(val) => setRow(val)}
        actions={{
          transferDetails: transferDetails,
        }}
        setOpen={setOpen}
      />
      {open && <ResPopup setClose={setOpen} data={dataPopup} />}
      {/* <Popup onOpen={BankDeatilPopup} onClose={setBankDeatilPopup}> */}
      {Popup && (
        <BankDeatil
          open={BankDeatilPopup}
          onClose={(close) => {
            setBankDeatilPopup(false);
            setbankData([]);
          }}
          PopupDetail={bankData}
        />
      )}
    </>
  );
}

export default PayoutDataTable;
