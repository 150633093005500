import React from "react";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Popup from "./popup";
import SettlementDataTable from "./settlementDataTable";
function SettlementContent() {
  const [popup, setPopup] = useState(false);

  const [wallet, setWallet] = useState({
    settleamount: 0.0,
    unsettleamount: 0.0,
  });
  const getWallet = async () => {
    try {
      const response = await axios.get(
        "https://api.sharkpe.in/merchant/wallet",
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success) {
        setWallet({
          settleamount: response.data?.data.amount,
          unsettleamount: response.data?.data.hold_amount,
        });
      }
    } catch (error) {
      setWallet([]);
    }
  };
  useEffect(() => {
    getWallet();
  }, []);

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="row">
          <div className="card">
            <div className="card-body ">
              <div className="d-flex justify-content-between align-item-center">
                <h4 style={{ color: "#9568FF" }} className="pt-2">
                  Payment Settlement
                </h4>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setPopup(true);
                    }}
                  >
                    Make Payment Settlement{" "}
                  </button>
                </div>
                {popup && (
                  <Popup
                    setPopup={setPopup}
                    settleamount={wallet.settleamount}
                    unsettleamount={wallet.unsettleamount}
                  />
                )}
              </div>
              {/* <SettlementContentTable/> */}
              
              <SettlementDataTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettlementContent;
