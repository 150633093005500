import React from "react";
import "../component/popup.css";

function ResPopup(props) {
  return (
    <div className="modalBackground">
      <div className="card col-xl-4" style={{ height: "350px" }}>
        <div className="card-body">
          <div className="row">
            <div className="col-12 p-3">
              <button
                style={{ border: "none", float: "right" }}
                onClick={() => {
                  props.setClose(false);
                }}
              >
                <i class="bi bi-x-square"></i>
              </button>
            </div>
            <div className="col-20">{props?.data}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResPopup;
