import React from 'react';
import'./content.css';
import { Link } from 'react-router-dom';
// import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function NavLinks(props) {
    // const [isDropDown , setDropDown]=useState(false);
    const navigate = useNavigate();

    // const dropmenu=<div >
    // <Link  className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Detail Dropdown" style={{ textDecoration:"none"}}>
    // <i className="fa-solid fa-house-laptop  text-white " ></i>
    //   <Link className="nav-link text-white " href="#">User</Link></Link>
      // <Link  className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Detail Dropdown" style={{ textDecoration:"none"}}>
    // <i className="fa-solid fa-house-laptop nav-link text-white " ></i>
    //   <Link className="nav-link text-white " to='/merchantDetail'>merchant</Link></Link>
    // </div>

  const handleLogOut=()=>{
    localStorage.clear();
    console.log('logout')
    navigate("/");
    console.log(localStorage.getItem('token'))
  }
  
    return (
        <div className={props.toggle?'nav-bar':'not-display'}>
        
        <Link className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Dashboard"  to='/dashboard' style={{ textDecoration:"none"}}>
        <i className="fa-solid fa-house-laptop nav-link text-white " ></i>
        <Link className="nav-link text-white" aria-current="page" to='/dashboard'>Dashboard</Link>
        </Link>

        <Link to='/payin' className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Pay In"  style={{ textDecoration:"none"}}>
        <i className="bi bi-send-fill nav-link text-white"></i>
        <Link className="nav-link text-white" to='/payin'>Payin</Link>
        </Link>

        <Link  to='/table' className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Pay Out" style={{ textDecoration:"none"}}>
        <i className="bi bi-wallet-fill nav-link text-white" to='/table'></i>
        <Link className="nav-link text-white" to='/table'>Payout</Link>
        </Link>
       
        <Link to='/settlement'className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Settlement" style={{ textDecoration:"none"}}>
        <i className="bi bi-arrow-repeat nav-link text-white"></i>
        <Link className="nav-link text-white" to='/settlement'>Settlement</Link>
        </Link>

        {/* <Link to='/addbank'className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Add Bank" style={{ textDecoration:"none"}}>
        <i className="bi bi-repeat nav-link text-white"></i>
        <Link className="nav-link text-white" to='/addbank'>Add Bank</Link>
        </Link> */}

        <Link to='/download'className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="export" style={{ textDecoration:"none"}}>
        <i className="bi bi-cloud-download nav-link text-white"></i>
        <Link className="nav-link text-white" to='/download'>Export</Link>
        </Link>

        {/* <button  className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Detail Dropdown" style={{backgroundColor:"transparent" ,border:'none'}} onClick={()=>{setDropDown(!isDropDown)}}>
        <i class="bi bi-caret-down-fill text-white md-1"></i>
       <div>  <button  className={props.toggle?'nav-link text-white':'d-none '} style={{backgroundColor:"transparent" ,border:'none'}} onClick={()=>{setDropDown(!isDropDown)}}>Detail</button>
      {isDropDown && dropmenu} 
       </div>
        </button> */}

        <Link className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Developer Docs" style={{ textDecoration:"none"}}>
        <i className="bi bi-file-earmark-text text-white"></i>
        <Link className="nav-link text-white" to= "/external-link"  >
        Developer Docs</Link>
        </Link>

        {/* <Link to='/account' className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Account"  style={{ textDecoration:"none"}}>
        <i className="bi bi-gear-wide-connected text-white"></i>
        <Link className="nav-link text-white" to='/account'>Account</Link>
        </Link> */}

        {/* <Link to='/paylink' className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Payment Links" style={{ textDecoration:"none"}}>
        <i className="bi bi-link text-white"></i>
        <Link className="nav-link text-white" to='/paylink'>payment Links</Link>
        </Link> */}

        <Link to='/payapi' className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="Payment API" style={{ textDecoration:"none"}}>
        <i className="bi bi-credit-card-2-front text-white"></i>
        <Link className="nav-link text-white" to='/payapi'>Payment API</Link>
        </Link>

        {/* <Link to='/payupi' className={props.toggle?'d-flex onhover':'d-flex onhover tt'} data-tooltip="SetUP API" style={{ textDecoration:"none"}}>
        <i className="bi bi-arrow-right text-white"></i>
        <Link className="nav-link text-white" to='/payupi'>SETUP-UPI</Link>
        </Link> */}
        
        <button  className={props.toggle?'d-flex onhover':'d-flex onhover tt'} style={{backgroundColor:"transparent" ,border:'none'}} data-tooltip="Log Out" onClick={handleLogOut}>
        <i className="bi bi-box-arrow-right text-white"></i>
        <div className="nav-link text-white">Log Out</div>
        </button>
    
    </div>
    );
}

export default NavLinks;