import React from 'react'
import { Formik, Form, useField } from 'formik';
import '../User-form/userForm.css'
import { useState } from 'react';
function AccountContent() {
const[able ,setAble] =useState(false);
const MyTextInput = ({ label, ...props }) => {
 
    const [field] = useField(props);
    return (
      <div className='col-xl-6 col-sm-6 p-2'>
        <label class="form-label text-muted " style={{fontSize:'13px'}}htmlFor={props.id || props.name}>{label}</label>
        {able? <input className="form-control  p-2" {...field} {...props}/>:<input class="form-control p-2 " type="text" {...field} {...props} disabled style={{background:'#fff' ,fontSize:'14px'}}/>}
       
      </div>
    );
  };
    return ( 
    <div className='row'>
        <div className='col-12'>
            <div className='card'>
                <div className='card-body'>
                    <h4 style={{color:'#9568FF'}}>Account Details</h4>
                </div>
            </div>
        </div>
        <div className='col-12'>
            <div className='card'>
                <div className='card-body'>
                <div className='row'>
                    
                <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          mobilenumber:'',
          referral:'',
          
        }}
        onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}>
            <Form className='card p-2'>
           <h4 className= 'title'>Details</h4> 
           <div className='row'>
         <MyTextInput
            label="First Name"
            name="firstName"
            type="text"
            placeholder="Jane"
          />

          <MyTextInput
            label="Last Name"
            name="lastName"
            type="text"
            placeholder="Doe"
          />
       
       
          <MyTextInput
            label="Email Address"
            name="email"
            type="email"
            placeholder="jane@formik.com"
          />

        <MyTextInput
            label="Mobile Number"
            name="mobilenumber"
            type="string"
            placeholder="0915847****"
          />
           {/* <MyTextInput
            label="Created ON"
            name="Createdon"
            type="string"
            placeholder="created on"
          /> */}
       <div className='col-xl-6 col-sm-12 p-3'>
        <div className='row'>
            {/* <div className='col-6'>
                <div className='row'>
                    <div className='text-muted'>Account Status</div>
                    <div className='text-success col-6 m-2' style={{border:'1px solid green' ,borderRadius:'10px'}}>Active</div>
                </div>
            </div> */}
            {/* <div className='col-6'>
            <div className='row'>
                    <div className='text-muted'>Verification Status</div>
                    <div className='text-success col-6 m-2'style={{border:'1px solid green' ,borderRadius:'10px'}}>Verified</div>
                </div>
            </div> */}
        </div>
       </div>
       </div>
        {able? <button className='btn btn-success col-sm-12 m-2' type="submit" >submit changes</button>:
        <button className='btn btn-primary col-sm-12 m-2' onClick={()=>{setAble(true)}}>Edit</button>}
        

        </Form>

        </Formik>
        
                </div>
                </div>
            </div>
        </div>
    </div> );
}

export default AccountContent;