import React from 'react';
import'../../component/popup.css'
import { Formik, Form, useField } from 'formik';
//import axios from 'axios';
const MyTextInput = ({ label, ...props }) => {
 
    const [field] = useField(props);
    return (
      <div className='m-2'>
        <label class="form-label text-muted" style={{fontSize:'12px'}}htmlFor={props.id || props.name}>{label}</label>
        <input className="form-control p-2" {...field} {...props} />
      </div>
    );
  };

function Popup({closepopup}) {

    return (
        <div className='modalBackground'>
            <div className='col-xl-6 m-2'>
               
                <Formik className='card'
                 initialValues={{
                    label: '',
                    beneficiary_name: '',
                    bank_name: '',
                    acc_number:'',
                    ifsc:'',
                   }}
                   onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      alert(JSON.stringify(values, null, 2));
                      setSubmitting(false);
                    }, 400);
                   // axios.post('url',values);
                  }}>
                    <div className='card'>
                    
                        <Form className='card-body'>
                        <div className='col-12'><button className='btn btn-danger' onClick={()=>{closepopup(false)}}>X</button></div>
                        <MyTextInput
                         label="label"
                         name="label"
                         type="text"
                        placeholder="label"
                        />
                         <MyTextInput
                         label="Beneficiary Name"
                         name="beneficiary_name"
                         type="text"
                        placeholder="beneficiary name"
                        />
                         <MyTextInput
                         label="Bank Name"
                         name="bank_name"
                         type="text"
                        placeholder="bank name"
                        />
                         <MyTextInput
                         label="Account Number"
                         name="acc_number"
                         type='string'
                        placeholder="account number"
                        />
                         <MyTextInput
                         label="IFSC"
                         name="ifsc"
                         type="text"
                        placeholder="IFSC"
                        />
                        <div className='col-12 d-flex justify-content-center'><button className='btn btn-success' type='submit'>Submit</button></div>
                        </Form>
                    </div>
                </Formik>
            </div>
        </div>
      );
}

export default Popup;
