import React, { useEffect, useState } from "react";
import BigChart from "./bigChart";
import BuySellCard from "./buySellCard";
import CardBubble from "./cardbubble";
import "./dashcontent.css";
import DashTable from "./DashTable";
import Swiper from "./swiper";
import axios from "axios";
import SalesAnalytics from "./salesAnalytics";

function DashContent() {
  const [wallet, setWallet] = useState({ amount: 0.00, hold_amount: 0.00 });
  const getWallet = async () => {
    try {
      const response = await axios.get(
        "https://api.sharkpe.in/merchant/wallet",
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success) {
        setWallet({
          amount: response.data?.data.amount,
          hold_amount: response.data?.data.hold_amount,
        });
      }
    } catch (error) {
      setWallet([]);
    }
  };
  useEffect(() => {
    getWallet();
  }, []);
  return (
    <div className="container-fluid bg-overall vh-100">
      <div className="row">
        <div className="col-xl-8">
          <div className="row">
            <div className="col-xl-12">
              {/* <CardBubble /> */}
            </div>
            <div className="col-xl-12">
            {/* <Swiper data={wallet} /> */}
            </div>
            {/* <div className='col-xl-12'><BigChart/></div>  */}
            <div className="col-xl-12"><SalesAnalytics wallet={wallet}/></div>
            <div className='col-xl-5 col-lg-12'></div>
            <div className='col-xl-4 col-sm-6'></div>
            <div className='col-xl-3 col-sm-6'></div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="row">
            {/* <div className="col-xl-12 col-sm-6"> <BuySellCard /></div> */}
            <div className="col-xl-12 col-sm-6">
              {/* <DashTable /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashContent;