import React from 'react'
function TransactionHistory() {
    return (  
    <div className='col-lg-3 col-sm-12'>
        <div className='title' style={{maxWidth:'fit-content'}}>Transaction History</div>
        <div className='mt-4'>
            <div className='text-muted' style={{fontSize:'13px'}}> Today</div>
            <div className='col-12 mt-3 card p-2 '>
                <div className='d-flex'>
                <i class="bi bi-bank col-3"></i>
                <div>
                    <h6>Baliando</h6>
                    <div className='text-muted' style={{fontSize:'13px'}}>Transfer to bank</div>
                </div>
                </div>
            </div>
        </div>
        <div className='mt-4'>
            <div className='text-muted' style={{fontSize:'13px'}}> Yesterday</div>
            <div className='col-12 card mt-3 p-2'>
                <div className='d-flex'>
            <i class="bi bi-clipboard2-heart col-3"></i>
                <div>
                    <h6>VIP Bistro</h6>
                    <div className='text-muted' style={{fontSize:'13px'}}>Cafe & Restraunt</div>
                </div>
                </div>
            </div>
            <div className='col-12 card p-2 mt-3'>
                <div className='d-flex'>
            <i class="bi bi-basket col-3"></i>
                <div>
                    <h6>Fun Home</h6>
                    <div className='text-muted' style={{fontSize:'13px'}}>Gaming</div>
                </div>
                </div>
            </div>

        </div>

        <div className='mt-4'>
            <div className='text-muted' style={{fontSize:'13px'}}> Earlier this month</div>
            <div className='col-12 card mt-3 p-2'>
                <div className='d-flex'>
            <i class="bi bi-clipboard2-heart col-3"></i>
                <div>
                    <h6>VIP Bistro</h6>
                    <div className='text-muted' style={{fontSize:'13px'}}>Cafe & Restraunt</div>
                </div>
                </div>
            </div>
            <div className='col-12 card p-2 mt-3'>
                <div className='d-flex'>
            <i class="bi bi-basket col-3"></i>
                <div>
                    <h6>Fun Home</h6>
                    <div className='text-muted' style={{fontSize:'13px'}}>Gaming</div>
                </div>
                </div>
            </div>

        </div>


    </div>);
}

export default TransactionHistory;