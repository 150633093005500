
import React from 'react';
import Sharkpe from '../icons/Sharkpe.svg'
import NavLinks from '../content/navlinks';
import'./header.css';
import Sidebar from '../content/sidebar';
import login from '../icons/login.png';

function Header(props) {

    return ( 
    <div className='header'>
     <React.Fragment>
          <NavLinks toggle={props.toggle}/>
          
       </React.Fragment>
    {/*navbar */}
     <div className='navigation'>
        <div className='d-flex'>
        <div className={props.toggle?'display-logo':''} >
           <img src={localStorage.getItem("logo")} alt='' style={{height:'4rem'}}/>
          
        </div>
       
        </div>
      {/**nav-top */} 
     <div className={props.toggle?'nav-top d-flex align-center justify-content-between':'nav-top-margin d-flex align-center justify-content-between'}>
    <div className='d-flex'> <button onClick={props.toggler} className='navbtn'><i class="bi bi-list"></i> </button>
     <div className='align-self-center title fw-bold'>{props.title}</div></div>
     <div className='d-flex align-self-center'>
    <form class="form-inline nav-search">
     <input class="form-control mr-sm-2 " type="search" placeholder="Search" aria-label="Search"/>
     </form>
  
     <div><img src={login} alt='' className='login-img desktop-img'/></div>
     </div>

     </div>
     {/**nav-top-ends */}
     </div>
   <Sidebar/>
    </div>
     );
}

export default Header;