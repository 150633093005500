import axios from "axios";
import { Formik } from "formik";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import Popup from "reactjs-popup";
// import "./model.css";
import "reactjs-popup/dist/index.css";

function BankDeatil({ open, onClose, PopupDetail }) {
  console.log('====================================');
  console.log({PopupDetail});
  console.log('====================================');
  return (
    <>
      <ToastContainer />
      <Popup open={open} modal onClose={onClose}>
        {PopupDetail && (
          <Formik
            initialValues={{
              walletType: "",
              amount: "",
              charges: "",
              user_id:PopupDetail.id
            }}
            //   validationSchema={Schema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              onClose(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className="text-center">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      Name : {PopupDetail.name}
                    </span>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                    account_number : {PopupDetail.account_number}
                    </span>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                    ifsc_code : {PopupDetail.ifsc_code}
                    </span>
                  </div>
                </div>
                {/* <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">
                      Select Wallet Type
                    </label>
                  </div>
                  <select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    class="custom-select"
                    id="inputGroupSelect01"
                    required
                    name="walletType"
                    value={values.walletType}
                  >
                    <option selected value={""}>
                      Choose...
                    </option>
                    <option value="hold_amount">
                      hold_amount:
                      {PopupDetail["cryptopay_wallet.hold_amount"].toFixed()}
                    </option>
                    <option value="amount">
                      amount:{PopupDetail["cryptopay_wallet.amount"].toFixed()}
                    </option>
                  </select>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      Amount
                    </span>
                  </div>
                  <input
                    type="text"
                    name="amount"
                    class="form-control"
                    placeholder="Enter Amount"
                    min={1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.amount}
                    required
                  />
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      Charges
                    </span>
                  </div>
                  <input
                    type="text"
                    name="charges"
                    class="form-control"
                    placeholder="Enter Charges"
                    min={0}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.charges}
                  />
                </div>
                <input
                    type="text"
                    name="user_id"
                    class="form-control"
                    placeholder=""
                    required
                    value={PopupDetail.id}
                  />
                <button type="submit" className="btn-primary">
                  {" "}
                  Deduct Now
                </button> */}
              </form>
            )}
          </Formik>
        )}
      </Popup>
      {/* <div className="modalBackground">
      <div className="card col-xl-4" style={{ height: "350px" }}>
        <div className="card-body">
          <div className="row">
          <div className='col-12 p-3'>
            <button style={{border:'none', float:'right'}}
             onClick={()=>{props.closepopup(false)}}><i class="bi bi-x-square"></i></button>
                </div>
            <div className="col-12">
            
              <div className="row">
                <div className="col-6 text-muted" style={{fontSize:'12px'}}> Settled Amount</div>
                <div className="col-6">{props.settleamount}</div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6 text-muted"  style={{fontSize:'12px'}}> UnSettled Amount</div>
                <div className="col-6">{props.unsettleamount}</div>
              </div>
            </div>
            <div className="col-10 mt-2">
              <div className="row">
                <div className="form-label mr-2">Amount</div>
                <input className="form-control p-2 mr-2" />
                <button className="btn btn-primary mt-2 col-7 col-md-5">Submit</button>
                
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div> */}
    </>
  );
}

export default BankDeatil;
