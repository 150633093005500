import React from 'react'
import './detailContent.css'
import DetailUser from './detailUser';
import TransactionHistory from './transactionHistory';

function DetailContent()
 {
   
    return (
     <div className='container card'>
        
            <div className='col-12 card-body'>
                <div className='row'>
                    <div className='col-12 d-flex justify-content-between align-item-center'>
                        <div className='d-flex align-item-center'>
                        <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50" className='avatar'/>
                         <h4 className='mt-3 p-2'>Daud Ibrahim</h4>
                         </div>
                         <div>
                            <button className='btn btn-primary m-2'>Wallet</button>
                            <button className='btn btn-primary m-2'>Wallet</button>
                         </div>
                    </div>
                   
                </div>
                <div className='row mt-4'>
                    <DetailUser/>
                    <TransactionHistory/>
                </div>
               
         </div>
         </div>
     );
}

export default DetailContent;