import React from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.css";
import moment from "moment";
import Header from "../component/header";
import HeaderMobile from "../component/headerMobile";
import axios from "axios";
import { CSVLink } from "react-csv";


function Download() {
  const [toggle, setToggle] = useState(true);

  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [Popup, setPopup] = useState(false);
  const [Data, setData] = useState([]);
  const toggler = () => {
    setToggle(!toggle);
  };

  const headers = [
    { label: "user_id", key: "user_id" },
    { label: "amount", key: "amount" },
    { label: "status", key: "status" },
    { label: "source", key: "source" },
    { label: "partner_txn_id", key: "partner_txn_id" },
    { label: "transaction_id", key: "transaction_id" },
    { label: "createdAt", key: "createdAt" },
  ];

  const handle = async (mode) => {
    // toast.success("Processing Your Request" + mode);
    try {
      const request = await axios.post(
        "https://api.sharkpe.in/merchant/transaction-export",
        {
          start_date: startdate,
          end_date: enddate,
          mode,
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setPopup(true);
      setData(request?.data);
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };

  return (
    <>
      <div className="userTable">
        <ToastContainer />
        <div className="header-desktop">
          {" "}
          <Header toggle={toggle} toggler={toggler} title={"Export"} />{" "}
        </div>
        <div className="header-mobile">
          <HeaderMobile toggler={toggler} toggle={toggle} />
        </div>
        <div
          className={toggle ? "body margin-active" : "body margin-non-active "}
        >
          <div className="row pt-3 m-3" style={{minHeight:'100vh'}}>
            <div className="card">
              <div className="card-body ">
                <div className="row justify-content-center align-item-center">
                  <div className="col-md-6 col-12">
                    <div className="row justify-content-center align-item-center">
                      {/* <label className="text-center">Start Date</label>
                    <input
                      id="startDate"
                      class="form-control"
                      type="date"
                      value={startdate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                    <label className="text-center">End Date</label>
                    <input
                      id="endDate"
                      class="form-control"
                      type="date"
                      value={enddate}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    /> */}
                      <DateRangePicker
                        className="form-control"
                        format="yyyy-MM-dd"
                        showOneCalendar={true}
                        onChange={(e) => {
                          setStartDate(
                            moment(e[0])
                              .startOf("day")
                              .format("YYYY-MM-DD HH:mm:ss")
                          );
                          setEndDate(
                            moment(e[1])
                              .endOf("day")
                              .format("YYYY-MM-DD HH:mm:ss")
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="row justify-content-center align-item-center gap-1 m-3">
                    <button
                      className="btn btn-info col-md-3 col-12"
                      onClick={() => handle("payin")}
                    >
                      PayIn
                    </button>

                    <button
                      className="btn btn-info col-md-3 col-12"
                      // onClick={handlePayout}
                      onClick={() => handle("payout")}
                    >
                      PayOut
                    </button>
                  </div>

                  <div className="row justify-content-center align-item-center gap-1 m-3">
                    {Popup && (
                      <CSVLink
                        onClick={() => setPopup(!Popup)}
                        data={Data?.transactions}
                        headers={headers}
                        filename={"report.csv"}
                        className="btn btn-primary"
                        target="_blank"
                      >
                        Download me
                      </CSVLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Download;
