import axios from 'axios';
import React from 'react';
import DataTable from 'react-data-table-component';
import { CSVLink } from "react-csv";

import { useEffect, useState } from 'react';

function PaymentLinkContent() {
    const [data, setData]=useState([])
    const [search, setSearch]=useState('')
    const [filterdata , setFilterdata]=useState([])
    const[open,setOpen]=useState(false);
    const[id, setId]=useState();
    // const getData = async()=>{
    //     try{
    //         const response = await axios.get('https://dummyjson.com/users');
    //         setData(response.data.users);
    //         setFilterdata(response.data.users);
    //     }
    //     catch(error){
    //         console.log(error);
    //     }
    // }
    // useEffect(()=>{getData();},[]);

    useEffect(()=>{
        const result= data.filter(info =>{
            return info.firstName.toLowerCase().match(search.toLowerCase());
        });
        setFilterdata(result);
    },[search,data])

    
    const columns=[
        {name:'name',
        selector:(row)=><>{row.firstName}<> </>{row.lastName}</>,
         sortable:true},
       
        {name:'email',
        selector:(row)=>row.email,
         sortable:true},

        {name:'birthdate',
        selector:(row)=> <>{row.birthDate}</>,
         sortable:true}   ,
         {name:'action',
         selector:(row)=> <div><div className='d-none'>{row.birthDate}</div>:</div>,
          sortable:true}   
                
    ]
    

    const customStyles = {
        rows: {
            style: {
                minHeight: '65px', 

            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', 
                paddingRight: '8px',
                fontSize:'16px',
                color:'#22173f'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', 
                paddingRight: '8px',
                color:'#6c757d'
            },
        },
       
    }; 
    const handleChange = ({ selectedRows }) => {
        console.log('Selected Rows: ', selectedRows);
      };
    const whenclick =(row, event) => {
      
        console.log('clicked row:', row);
        setId(row.id);
        console.log(id);
        setOpen(true)
      }

    return (  
        <>
                <DataTable
        title='Payment Link Database'
        columns={columns}
        data={filterdata}
        pagination
        fixedHeader
        selectableRows
        selectableRowsHighlight 
        highlightOnHover 
        actions={<CSVLink data={data} onClick={() => {
            console.log("You click the link"); // 👍🏻 Your click handling logic
          }} className="btn btn-info" >export</CSVLink>}    
        subHeader
        subHeaderComponent ={
            <input
             type='text' 
             placeholder='search' 
             className='form-control w-25'
             value={search}
             onChange={(e)=>setSearch(e.target.value)} />
        } 
        customStyles={customStyles}
        onRowClicked={whenclick}
      onSelectedRowsChange={handleChange}
        />
     {/* {console.log('send',id)}
     {open && <Popup id={id} setclose={setOpen}/>} */}
         </> 
    );
}

export default PaymentLinkContent;