import React from 'react';
import'./content.css';
import login from '../icons/login.png';
import flag from'../icons/indian-flag.png';
function Sidebar() {
    return ( <div className='sidebar '>
          <div className='avatar'>
        <img alt=''src={login}/>
     </div>
          <div className='sidebar-btn'>
            <button className='big-btn btn-one'><i class="bi bi-gear-fill spin"></i></button>
            <button className='big-btn btn-two'><i class="bi bi-droplet-half"></i></button>
        </div>

            <div className='sidebar-btn'>
             <button className='icon-btn'><i class="bi bi-gear-fill"></i></button>
            <button className='icon-btn'><i class="bi bi-chat-left-fill"></i></button>
            <button className='icon-btn'><i class="bi bi-bell-fill"></i></button>
            </div>
<img src={flag} alt='' className='flag'/>
          
    </div> );
}

export default Sidebar;
