import React, { useEffect, useState } from "react";
import "../../component/popup.css";
import axios from "axios";

function Popup(props) {
  const [api, setApi] = useState("");
  const id = props.id;
  const getData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: token };
      const response = await axios.get(
        `https://api.sharkpe.in/merchant/api/${id}`,
        { headers: headers }
      );
      // console.log(response.data.data.api_key);
       setApi(response.data.data.api_key);
      console.log(response)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleClick = () => {
    navigator.clipboard.writeText(api);
    alert("api copied to your clipboard");
  };
  return (
    <div className="modalBackground">
      <div className="card col-xl-4" style={{ height: "250px" }}>
        <div className="card-body">
          <div className="row">
            <button
              className=" col-12"
              style={{
                backgroundColor: "transparent",
                border: "none",
                fontSize: "2rem",
               textAlign:'right',
               color:'red'
              }}
              onClick={() => {
                props.setClose(false);
              }}
            >
              <i class="bi bi-x"></i>
            </button>
            <label htmlFor="api" className="mt-4">
              Your Api Key
            </label>

            <input value={api} className="form-control mt-2" placeholder={api}/>
            <button className="btn btn-primary mt-2" onClick={handleClick}>
              copy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
