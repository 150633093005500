import React from "react";
import "../detail-content/detailContent.css";
import { useState, useEffect } from "react";
import axios from "axios";
function MerchantDContent() {
  const [data, setData] = useState([]);
  const getData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: token };
      const response = await axios.get(
        "https://api.sharkpe.in/merchant/profile",
        { headers: headers }
      );
      console.log(response.data.data);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="col-12 mb-4">
      <div className="title" style={{ maxWidth: "fit-content" }}>
        General Information
      </div>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="mt-3">
              <div className="text-muted mb-1" style={{ fontSize: "13px" }}>
                {" "}
                User Type
              </div>
              <div style={{ textTransform: "capitalize" }}>{data.userType}</div>
            </div>
          </div>
          <div className="row">
            <div className="mt-3">
              <div className="text-muted mb-1" style={{ fontSize: "13px" }}>
                {" "}
                First Name
              </div>
              <div style={{ textTransform: "capitalize" }}>{data.fname}</div>
            </div>
            <div className="mt-3">
              <div className="text-muted mb-1" style={{ fontSize: "13px" }}>
                {" "}
                Last Name
              </div>
              <div style={{ textTransform: "capitalize" }}>{data.lname}</div>
            </div>
          </div>
        </div>
       
      </div>
      <div
        className="row mt-5 pt-4"
        style={{ borderTop: "2px solid rgb(222, 220, 220)" }}
      >
        <div className="title" style={{ maxWidth: "fit-content" }}>
          More
        </div>
        <div className="row">
          {/* <div class="dropdown col-6">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="bi bi-wallet-fill"></i> **** **** **346
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
         <a class="dropdown-item" href="#">Action</a>
        <a class="dropdown-item" href="#">Another action</a>
        <a class="dropdown-item" href="#">Something else here</a>
        </div>
        </div> */}
          <div className="col-lg-6 col-sm-12 p-2">
            <div className="row">
          <div className="mt-3">
              <div className="text-muted" style={{ fontSize: "13px" }}>
                User Name
              </div>
              <div>{data.username}</div>
            </div>
            <div className="mt-3">
              <div className="text-muted" style={{ fontSize: "13px" }}>
                Email
              </div>
              <div>{data.email}</div>
            </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default MerchantDContent;
