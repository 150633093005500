import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Schema = Yup.object({
  label: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("*required"),

  callback_url: Yup.string().required("*required"),
  webhook_url: Yup.string().required("*required"),
  ip_address: Yup.string().required("*required"),
});

function PaymentApiForm(props) {
  return (
    <div className="row m-1">
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={{
              label: "",
              callback_url: "",
              webhook_url: "",
              ip_address: "",
            }}
            validationSchema={Schema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              const token = localStorage.getItem("token");
              const headers = { Authorization: token };
              if (!values) {
                toast.error("empty string");
              } else {
                await axios
                  .post("https://api.sharkpe.in/merchant/api/", values, {
                    headers: headers,
                  })
                  .then(function (response) {
                    console.log(response.data.message);
                    toast.success(response.data.message);
                    // props.setReload(!props.reload);
                    window.location.href = "";
                    console.log(props.reload);
                  })
                  .catch((error) => {
                    console.log(error.response.data.message);
                    toast.error(error.response.data.message);
                  });
              }
              resetForm();
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <div>
                <div className="form pt-2">
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="container">
                      <div className="row">
                        <ToastContainer />
                        <div className="col-12 col-md-6">
                          {/* enter label*/}
                          <input
                            type="string"
                            name="label"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.label}
                            placeholder="label"
                            className="form-control "
                            id="label"
                          />

                          <p
                            className="error text-danger"
                            style={{ fontSize: "10px" }}
                          >
                            {errors.label && touched.label && errors.label}
                          </p>
                        </div>
                        <div className="col-12 col-md-6">
                          {/* enter callbark_url */}
                          <input
                            type="string"
                            name="callback_url"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.callback_url}
                            placeholder="Enter callback url"
                            className="form-control"
                          />

                          <p
                            className="error text-danger"
                            style={{ fontSize: "10px" }}
                          >
                            {errors.callback_url &&
                              touched.callback_url &&
                              errors.callback_url}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          {/* enter webhook_url */}
                          <input
                            type="string"
                            name="webhook_url"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.webhook_url}
                            placeholder="Enter webhook url"
                            className="form-control"
                          />

                          <p
                            className="error text-danger"
                            style={{ fontSize: "10px" }}
                          >
                            {errors.webhook_url &&
                              touched.webhook_url &&
                              errors.webhook_url}
                          </p>
                        </div>
                        <div className="col-12 col-md-6">
                          {/* enter ip_address */}
                          <input
                            type="string"
                            name="ip_address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ip_address}
                            placeholder="ip address"
                            className="form-control"
                          />

                          <p
                            className="error text-danger"
                            style={{ fontSize: "10px" }}
                          >
                            {errors.ip_address &&
                              touched.ip_address &&
                              errors.ip_address}
                          </p>
                        </div>
                      </div>
                      <button
                        className="btn btn-primary col-5 col-md-2"
                        type="submit"
                        onSubmit={handleSubmit}
                      >
                        send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default PaymentApiForm;
