import axios from 'axios';
import React from 'react';
import CommanTable from '../../pages/commanTable';

import { useEffect, useState } from 'react';
//import Popup from './popUp copy';
function AddBankTable(props) {
    const [data, setData] = useState([]);
    const [page, setPage] = useState("1");
    const [row, setRow] = useState("10");
    const [total, setTotal] = useState(0);
   //const[open, setOpen]=useState(false);
   
    const title=<div> <h3>Add Bank</h3> </div>
    const getData = async () => {
    //   try {
    //     const token = localStorage.getItem("token");
    //     const headers = { Authorization: token };
    //     const response = await axios.get(
    //     //   "https://api.sharkpe.in/merchant/api/",
    //       { headers: headers }
    //     );
    //     if(response.data.data.rows){
    //       console.log(response.data.data.rows);
  
    //       setTotal(response?.data.rows);
    //       setData(response.data.data.rows);
    //     }
      
    //   } catch (error) {
    //     console.log(error);
    //   }
    };
    useEffect(() => {
      getData();
    }, [props.reload]);
  
    

    return (  
        <>  <CommanTable
        data={data}
        title={title}
        setTotal={setTotal}
        setPage={(val) => setPage(val)}
        row={row}
        page={page}
        total={total}
        setRow={(val) => setRow(val)}
        actions={{
         
        }}
      />
     {/* {console.log('send',id)} */}
     {/* {open && <Popup id={id} setclose={setOpen}/>} */}
         </> 
    );
}

export default AddBankTable;