import React from 'react'
import'./pages.css'
import DetailContent from '../content/detail-content/detailContent';
import { useState } from 'react';
import Header from '../component/header';
import HeaderMobile from '../component/headerMobile';
function Detail() {
    const[toggle ,setToggle] = useState(true);
    const toggler=()=>{
        setToggle(!toggle);
    }
   
    return ( 
    <div className='detail'>
         <div className='header-desktop'> <Header toggle={toggle} toggler={toggler} title={'User Detail'}/> </div>
        <div className='header-mobile'><HeaderMobile toggler={toggler} toggle={toggle}/></div>
       
      
          <div className={toggle?'body margin-active':'body margin-non-active '}>
          <div  style={{minHeight:'100vh'}}>
          <DetailContent/>
          </div>
           
          </div>
    </div> 
    );
}

export default Detail;