import React from 'react';
import './pages.css';
import { Link } from 'react-router-dom';

function Resetpassword() {
    return (
  <div className="forget-password">
    <div className='d-flex p-2'>
        <h1 className='fw-bolder'>Shark</h1><h1>pe</h1></div>
    <h3 className='resetpass'>Reset Password</h3>
    <p className="text-center">Enter the email adress associated with your account<br/>
    and we we'll send you a link to reset your password.</p>
    <div className="col-lg-4 m-2">
            <input
              type="email"
              placeholder="Email-Address"
              className="form-control"
            ></input>
          </div>
   
   <button type='submit'className='btn btn-primary m-2'>Continue</button>
   <p>Dont have an account? <Link to='/signup'>Sign up</Link></p>
   
  </div>
);
}

export default Resetpassword;