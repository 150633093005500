import React from 'react';
import { useState } from 'react';
import AddBankTable from './addBankTable';
import Popup from './Popup';

function AddBankContent() {
    const[popup , setPopup]=useState(false);
    return (
     <div className='row'>
        <div className='col-xl-12'>
            <div className='row'>
                <div className='card'>
                    <div className='card-body '>
                        <div className='d-flex justify-content-between align-item-center'>
                         <h4 style={{color:'#9568FF'}} className='pt-2'>Add Bank</h4>
                        <div><button className='btn btn-primary' onClick={()=>{setPopup(true)}}>Add Bank</button></div>
                        {popup && <Popup closepopup={setPopup}/>}
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div className='col-xl-12'>
            <div className='row'>
               <div className='card'>
                <div className='card-body'>
                    <AddBankTable/>
                </div>
               </div>
            </div>
        </div>
    </div> );
}

export default AddBankContent;