import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

// const Schema = Yup.object({
//   first_name: Yup.string()
//     .max(15, "Must be 15 characters or less")
//     .required("*Required"),
//   last_name: Yup.string()
//     .max(20, "Must be 20 characters or less")
//     .required("*Required"),
//   number: Yup.string()
//     .max(10, "Must be 10 characters")
//     .min(10, "Must be 10 characters")
//     .required("*Required"),
//   username: Yup.string()
//     .max(20, "Must be 20 characters or less")
//     .required("*Required"),

//   email: Yup.string().email("*Invalid email address").required("*Required"),

//   acceptedTerms: Yup.boolean()
//     .required("*Required")
//     .oneOf([true], "*You must accept the terms and conditions."),
//   password: Yup.string()
//     .required("No password provided.")
//     .min(8, "Password is too short - should be 8 chars minimum.")
//     .matches(/(?=.*[0-9])/, "Password must contain a number."),
//   confirmpassword: Yup.string().oneOf(
//     [Yup.ref("password"), null],
//     "Passwords must match"
//   ),
// });

const SignupForm = () => {
  const [button, setbutton] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className="sign-up pt-5" style={{ minHeight: "100vh" }}>
        <div className="signupLink mt-3">
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              number: "",
              username: "",
              email: "",
              password: "",
              confirmpassword: "",
              source: window.location.host.split(".")[1],
            }}
            validate={(values) => {
              const errors = {};
              if (!values.first_name) {
                errors.first_name = "Required";
              }
              if (!values.last_name) {
                errors.last_name = "Required";
              }
              if (!values.number) {
                errors.number = "Required";
              }
              if (!values.username) {
                errors.username = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.password) {
                errors.password = "Required";
              }
              if (!values.confirmpassword) {
                errors.confirmpassword = "Required";
              } else if (values.password !== values.confirmpassword) {
                errors.confirmpassword = "Passwords do not match";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              console.log(values);
              setbutton(true);
              axios
                .post("https://api.sharkpe.in/register/", values)
                .then(function (response) {
                  console.log(response.data.message);
                  if (response.data.success) {
                    toast.success(response.data.message);
                    setbutton(false);
                    navigate("/");
                  } else {
                    alert("invalid credentials");
                  }
                })
                .catch((error) => {
                  setbutton(false);
                  console.log(error.response.data.message);
                  toast.error(error.response.data.message);
                });
              // setbutton(false)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <>
                <ToastContainer />
                <div className="d-flex">
                  <h1 className="fw-bolder">Sign</h1> <h1>Up</h1>
                </div>
                <Form onSubmit={handleSubmit} onChange={() => setbutton(false)}>
                  <Field
                    type="text"
                    name="first_name"
                    className="form-control"
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                  <p className="error text-danger" style={{ fontSize: "10px" }}>
                    {errors.first_name &&
                      touched.first_name &&
                      errors.first_name}
                  </p>
                  <Field
                    type="text"
                    name="last_name"
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                  <p className="error text-danger" style={{ fontSize: "10px" }}>
                    {errors.last_name && touched.last_name && errors.last_name}
                  </p>
                  <Field
                    type="text"
                    name="number"
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Number"
                  />
                  <p className="error text-danger" style={{ fontSize: "10px" }}>
                    {errors.number && touched.number && errors.number}
                  </p>
                  <Field
                    type="text"
                    name="username"
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Username"
                  />
                  <p className="error text-danger" style={{ fontSize: "10px" }}>
                    {errors.username && touched.username && errors.username}
                  </p>
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  <p className="error text-danger" style={{ fontSize: "10px" }}>
                    {errors.email && touched.email && errors.email}
                  </p>
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <p className="error text-danger" style={{ fontSize: "10px" }}>
                    {errors.password && touched.password && errors.password}
                  </p>
                  <Field
                    type="password"
                    name="confirmpassword"
                    onChange={handleChange}
                    placeholder="Confirm Password"
                    className="form-control"
                  />
                  <p className="error text-danger" style={{ fontSize: "10px" }}>
                    {errors.confirmpassword &&
                      touched.confirmpassword &&
                      errors.confirmpassword}
                  </p>
                  <Field
                    type="hidden"
                    name="source"
                    placeholder="WebSource"
                    className="form-control"
                    onChange={handleChange}
                    value={window.location.host.split(".")[1]}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary col-12 mt-4"
                    disabled={button}
                  >
                    Submit
                  </button>
                </Form>
              </>
            )}
          </Formik>
          <div> Already have an account?</div>
          <Link to="/" className="btn btn-success">
            Log In
          </Link>
        </div>
      </div>
    </>
  );
};
export default SignupForm;
