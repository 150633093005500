import React from 'react'
import { useState } from 'react';
import Header from '../component/header';
import HeaderMobile from '../component/headerMobile';
import Cards from '../content/User-form/cards';
import UseForm from '../content/User-form/useForm';


function UserForm() {
    const[toggle ,setToggle] = useState(true);
    const toggler=()=>{
        setToggle(!toggle);
    }
    return (
    <div className='userForm'>
        <div className='header-desktop'> <Header toggle={toggle} toggler={toggler} title={'User Detail'}/> </div>
        <div className='header-mobile'><HeaderMobile toggler={toggler} toggle={toggle}/></div>
     <div className={toggle?'body margin-active':'body margin-non-active '}>
    
  <div className='flex-userform'>
     <div className=' cards'>
        <div className='row'><Cards/></div>
     </div>
    

        
     <div className=' useform '>
     <div className='row'> <UseForm/></div>
        
    </div>
    </div>
</div>

</div>
   
     );
}

export default UserForm;