import React, { useState } from "react";
import Header from "../component/header";
import HeaderMobile from "../component/headerMobile";
import "./pages.css";
import DashContent from "../content/dashboard-content/dashcontent";

function Dashboard() {

  const [toggle, setToggle] = useState(true);
  const toggler = () => {
    setToggle(!toggle);
  };
  

  return (
    <>
      <div className="dashboard">
        <div className="header-desktop">
          {" "}
          <Header toggle={toggle} toggler={toggler} title={"Dashboard"} />{" "}
        </div>
        <div className="header-mobile">
          <HeaderMobile toggler={toggler} toggle={toggle} />
        </div>

        <div
          className={toggle ? "body margin-active" : "body margin-non-active "}
        >
          <DashContent />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
