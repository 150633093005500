import React from 'react'
import './detailContent.css'
function DetailUser() {
    return (
     <div className='col-sm-12 col-lg-9 mb-4'>
        <div className='title' style={{maxWidth:'fit-content'}}>General Information</div>
        <div className='row'>
            <div className='col'>
                <div className='row'>
                <div className='mt-3'>
                <div className='text-muted mb-1' style={{fontSize:'13px'}}> Cardholder Customer ID</div>
                <div>#576893</div>
                </div>
                <div className='mt-3'>
                <div className='text-muted mb-1' style={{fontSize:'13px'}}> Cardholder Address</div>
                <div>11 West 53 Street, New York, NY 10019</div>
                </div>
                </div>
            </div>
            <div className='col'>
            <div className='row'>
                <div className='mt-3'>
                <div className='text-muted mb-1' style={{fontSize:'13px'}}> Cardholder Code</div>
                <div>852963</div>
                </div>
                <div className='mt-3'>
                <div className='text-muted mb-1' style={{fontSize:'13px'}}> Cardholder Date Of Birth</div>
                <div>04/03/1992</div>
                </div>
                </div>
            </div>
        </div>
        <div className='row mt-5 pt-4' style={{ borderTop:"2px solid rgb(222, 220, 220)"}}>
            <div className='title' style={{maxWidth:'fit-content'}}>Cards</div>
            <div className='row'>
            <div class="dropdown col-6">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bi bi-wallet-fill"></i> **** **** **346
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
             <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
            </div>
            </div>
            <div className='col-lg-6 col-sm-12 p-2'>
                <div className='row'>
            <div className='col'>
                <div className='text-muted' style={{fontSize:'12px'}}>Status</div>
                <div style={{color:'green'}}>Active</div>
            </div>
            <div className='col'>
                <div className='text-muted' style={{fontSize:'12px'}}>Expiry Date</div>
                <div>04/2024</div>
            </div>
            </div>
            </div>
            </div>
            <div className='row mt-5 mt-2'>

                <div className='col-lg-6 col-sm-12 mb-2'>
                    <div style={{color:'rgb(150, 150, 150)'}}>WALLETS</div>
                    
                    <div className='mt-3'>
                        <div className='text-muted' style={{fontSize:'13px'}}>Dollar</div>
                        <div>$14,230.20</div>
                    </div>
                    <div className='mt-3'>
                        <div className='text-muted' style={{fontSize:'13px'}}>Nigerian Naira</div>
                        <div>N25,000,00</div>
                    </div>
                
                </div>
                <div className='col-lg-6 col-sm-12 mb-2'>
                <div style={{color:'rgb(150, 150, 150)'}}>FREE OVERVIEW</div>  
                <div className='mt-3'>
                        <div className='text-muted' style={{fontSize:'13px'}}>Monthly Card Fee</div>
                        <div>10%</div>
                    </div>
                    <div className='mt-3'>
                        <div className='text-muted' style={{fontSize:'13px'}}>Free Type Transaction</div>
                        <div>Flat Fee</div>
                    </div>
                </div>
            </div>
        </div>
    </div> );
}

export default DetailUser;