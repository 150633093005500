import React from 'react';
import'./pages.css'

import { useState } from 'react';
import Header from '../component/header';
import HeaderMobile from '../component/headerMobile';
import MerchantDContent from '../content/merchantDetailContent/merchantDContent';
function MerchantDetail() {
    const[toggle ,setToggle] = useState(true);
    const toggler=()=>{
        setToggle(!toggle);
    }
    return ( 
        <div className='detail'>
        <div className='header-desktop'> <Header toggle={toggle} toggler={toggler} title={'Merchant Detail'}/> </div>
       <div className='header-mobile'><HeaderMobile toggler={toggler} toggle={toggle}/></div>
      
     
         <div className={toggle?'body margin-active':'body margin-non-active '}>
            <div className='card'>
                <div className='card-body'> <MerchantDContent/></div>
            </div>
        
         </div>
   </div>  
    );
}

export default MerchantDetail;