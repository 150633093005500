import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import axios from "axios";
import { Link } from "react-router-dom";

function CommanTable({
  data,
  actions,
  title,
  total,
  setRow,
  setPage,
  pageId,
  setTotal,
  setPopup,
  setOpen,
}) {
  //variable declaration
  let columns;
  const [search, setSearch] = useState("");
  const [filterdata, setFilterdata] = useState(data);
  const [isdropdown, setDropdown] = useState(false);
  //setting up table column if data exist
  if (data[0]) {
    console.log("data exist");
    columns = Object.keys(data[0]).map((key) => {
      if (key === "status") {
        return {
          name: key,
          selector: (row) => {
            let status;
            if (row["status"] === 0) {
              status = <span class="badge text-bg-warning">pending</span>;
            } else if (row["status"] === 1) {
              status = <span class="badge text-bg-success">Success</span>;
            } else if (row["status"] === 3) {
              status = <span class="badge text-bg-danger">Failed</span>;
            }
            return <>{status}</>;
          },
          wrap: true,
        };
      }
      return {
        name: key,
        selector: (row) => row[key],
        wrap: true,
        conditionalCellStyles: [
          {
            when: (row) => row["status"] === "1",
            classNames: "text-primary ",
          },
        ],
      };
    });

    columns.push({
      name: "Actions",
      cell: (row) => (
        <>
          <span className="fs-3 fw-bold" data-bs-toggle="dropdown">
            ⋮
          </span>
          <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
            {/* {Object.keys(actions).map((key) => {
              return (
                <>
                  <li>
                    <span
                      class="dropdown-item"
                      onClick={() => console.log(actions[key]())}
                    >
                      {key}
                    </span>
                    {/* <button
                    class="dropdown-item"
                    // href={`https://api.sharkpe.in/admin/${key}/${row.id}`}
                    onClick={key}
                  >
                    {key}
                  </button> */}
                  {/* </li>
                </>
              );
            })}  */}
            {Object.keys(actions).map((key, index) => {
            return (
              <>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    onClick={() => actions[key](row)}
                  >
                    {key}
                  </a>
                </li>
              </>
            );
          })}
          </ul>
        </>
      ),
    });
  } else {
    console.log("empty string");
  }

  //setting up search function
  const searchData = async () => {
    if (search && pageId) {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: token };
        const response = await axios.get(
          `https://api.sharkpe.in/merchant/search/${search}/${pageId}`,
          { headers: headers }
        );
        console.log(response);
        setTotal(response?.data.rows);
        //console.log(`https://api.sharkpe.in/merchant/search/${search}/${pageId}`)
        setFilterdata(response.data.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      setFilterdata(data);
    }
  };
  useEffect(() => {
    setFilterdata(data);
    searchData();
  }, [search, pageId, data]);

  const Dropdown = () => {
    // return(<div className="csvlink"><CSVLink
    //   data={data}
    //   onClick={() => {
    //     console.log("You click the link"); // 👍🏻 Your click handling logic
    //   }}
    //   className="text-primary dropdown-links"
    // >
    //   By date{" "}
    // </CSVLink></div>);
  };

  return (
    <>
      <DataTable
        actions={
          <div className="dropdown mt-1">
            <Link
              className="btn btn-sm p-2 btn-info d-flex gap-1"
              to={"/download"}
            >
              <div className="pr-1">export</div>
            </Link>
          </div>
          // <div>
          //   <button className="btn p-2 btn-info-sm">Download</button>
          // </div>
          //   <div><CSVLink
          //   data={data}
          //   onClick={() => {
          //     console.log("You click the link"); // 👍🏻 Your click handling logic
          //   }} className='btn p-2 btn-info'
          // >
          //   export{" "}
          // </CSVLink></div>
        }
        data={filterdata}
        columns={columns}
        title={title}
        fixedHeader={true}
        highlightOnHover={true}
        onChangePage={(pageid) => setPage(pageid)}
        onRowClicked={(row) => {
          setPopup(row);
          setOpen(true);
        }}
        onChangeRowsPerPage={(pp) => setRow(pp)}
        pagination
        responsive={true}
        progressPending={data.length === 0 && "pending"}
        striped
        paginationServer
        selectableRows
        subHeader
        paginationTotalRows={total}
        paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
        subHeaderComponent={
          <input
            type="text"
            placeholder="search"
            className="form-control w-25"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        }
      />
    </>
  );
}

export default CommanTable;
