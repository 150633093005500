import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import CommanTable from "../../pages/commanTable";
import Popup from "../settlement-content/popup";
import ResPopup from "../../pages/ResPopup";

function PayinTable(props) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState("1");
  const [row, setRow] = useState("10");
  const [total, setTotal] = useState(0);

  const getData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: token };
      const response = await axios.get(
        `https://api.sharkpe.in/merchant/transaction/${page}/${row}/payin`,
        { headers: headers }
      );
      console.log(response.data.data);
      setTotal(response?.data.rows);

      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [dataPopup, setdataPopup] = useState("");
  const getresponse = async () => {
    try {
      
      const token = localStorage.getItem("token");
      const headers = { Authorization: token };
      const response = await axios.get(
        `https://api.sharkpe.in/merchant/transaction_status/${popup}/payin`,
        { headers: headers }
      );
      setdataPopup(response.data.data.response);
    } catch (error) {
      console.log(error);
    }
  };
  const [popup, setPopup] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // setOpen(true);
    setdataPopup("")
    getresponse();
  }, [popup]);

  useEffect(() => {
    console.log({open});
    getData();
  }, [props.reload, page, row]);

  return (
    <>
      <CommanTable
        data={data}
        title="Payin"
        pageId="payin"
        setPage={(val) => setPage(val)}
        row={row}
        page={page}
        total={total}
        setTotal={setTotal}
        setRow={(val) => setRow(val)}
        actions={{}}
        setPopup={(row) => {setPopup(row.transaction_id) ;
        }}
        setOpen={
          setOpen
        }
      />
      {open && <ResPopup setClose={setOpen} data={dataPopup} />}
    </>
  );
}

export default PayinTable;
