import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import CommanTable from "../../pages/commanTable";

function SettlementDataTable(props) {
  const [data, setData] = useState([]);
  //const [search, setSearch] = useState("");
  // const [filterdata, setFilterdata] = useState([]);
   const [open, setOpen] = useState(false);
  //const [id, setId] = useState();
  const [page, setPage] = useState("1");
  const [row, setRow] = useState("10");
  const [total, setTotal] = useState(0);
  const getData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: token };
      const response = await axios.get(
        `https://api.sharkpe.in/merchant/transaction/${page}/${row}/settelment`,
        { headers: headers }
      );
      console.log(response.data);

      //  setFilterdata(response.data.data);
      setData(response?.data.data);
      setTotal(response?.data.rows);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, [props.reload, row, page]);
  return (
    <>
      <CommanTable
        data={data}
        title="Settlement"
        pageId="settlement"
        setPage={(val) => setPage(val)}
        row={row}
        page={page}
        total={total}
        setRow={(val) => setRow(val)}
        actions={{}}
        setTotal={setTotal}
        setOpen={
          setOpen
        }
      />
    </>
  );
}

export default SettlementDataTable;
